.header {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5vh;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
  position: relative;
}

.headerButton:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.logo {
  width: 3vw;
  max-width: 100px;
  height: auto;
  margin-right: 1vw;
  border-radius: 50%;
}

.logoList {
  width: 3vw;
  max-width: 100px;
  height: auto;
  margin-right: 1vw;
}

.headerButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: left;
  /* margin-right: 10px; */
}

.ovalBorder {
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 10px;
}

.cardContainer {
  position: absolute;
  top: 100%; /* Position it below the header */
  right: 0; /* Align it to the right side of the header */
}

.card {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  width: 80%; /* Make the width relative to the parent container */
  max-width: 200px; /* Set a maximum width */
  margin: 0 auto; /* Center the card */
  z-index: 1; /* Make the card appear above other elements */
  position: relative; /* Required for z-index to work */
  background-color: white;
}

.row {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}

.row:hover {
  background-color: hsl(0, 0%, 95%);

}

.row:last-child {
  border-bottom: none;
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .card {
    width: 100%; /* Make the card take up the full width on small screens */
  }
}