body {
  font-family: 'Helvetica', sans-serif;
  width: 60%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

/* Reduce font size on mobile screens */
@media screen and (max-width: 600px) {
  .container h1 {
    font-size: 2em; /* adjust as needed */
  }
}

h1 {
  margin: 0;
  font-size: 6em;
}

.logo {
  width: 200px;
  height: auto;
}