body {
  font-family: 'Helvetica', sans-serif;
  width: 80%; /* set the width of the body to 60% of the viewport width */
  margin: 0 auto;
}

.container{
  display: flex;

  align-items: center;
  gap: 10px;
}

.button {
 visibility: hidden;

}

.submitButton {
  background-color: #4CAF50; /* for example */
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.submitButton:hover {
  background-color: #45a049;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.input {
  display: block;
  width: 60%;
  height: 3vh; /* Adjust as needed */
  padding: 6px 12px; /* Adjust as needed */
  font-size: 14px; /* Adjust as needed */
  line-height: 1.42857143; /* Adjust as needed */
  color: #555; /* Adjust as needed */
  background-color: #fff; /* Adjust as needed */
  background-image: none; /* Adjust as needed */
  border: 1px solid #ccc; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075); /* Adjust as needed */
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; /* Adjust as needed */
}