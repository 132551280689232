/* styles.module.css */

.table {
  width: 70%;
  margin: auto;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
  text-align: left;
}

.table .grayRow {
  background-color: lightgray;
}

.avatar {
  width: 3vw;
  max-width: 100px;
  height: auto;
  margin-right: 1vw;
  border-radius: 50%; /* This will make the avatars round */
}

.rankingColumn {
  width: 5%;
}
.avatarColumn {
  width: 10%;
}
.nameColumn {
  width: 30%;
}
.scoreColumn {
  width: 30%;
}
.predictionsColumn {
  width: 25%;
}

.paginationContainer {
  display: flex;
  font-family: sans-serif;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px;
  cursor: pointer;
}

.pagination a {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.pagination a:hover {
  background-color: #ddd;
}

.active {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ddd;
}

.rightAlignContainer {
  float: right;
}

.leftAlignContent {
  text-align: left;
}

.boldUnderlined {
  text-decoration: underline;
  font-weight: bold;
}