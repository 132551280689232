body {
  font-family: 'Helvetica', sans-serif;
  width: 80%; /* set the width of the body to 60% of the viewport width */
  margin: 0 auto;
}

.container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.children {
  flex: 1 0 200px; /* adjust this value as needed */
  margin-right: 5vw;
}

@media (max-width: 600px) {
  .children {
    flex: 1 0 100%;
  }
}

.submitButton {
  background-color: #4CAF50; /* for example */
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.submitButton:hover {
  background-color: #45a049;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.box {
  border: 1px solid #000;
  padding: 2%; /* relative to the width of the box */
  margin: 2vh 0; /* relative to the height of the viewport */
  width: 80%; /* relative to the width of the parent element */
  max-width: 600px; /* optional, to prevent the box from becoming too wide on large screens */
}

.backButton {
  background-color: gray; /* for example */
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition-duration: 0.4s;
}